/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.custom_loading{
    .loading-wrapper{
      background: transparent;
      box-shadow: none;
    }
    ion-spinner{
      display: none !important;
    }
    img{
  animation-name: beat;
  animation-duration: 1s;
  animation-iteration-count: infinite;

}
    }
    @keyframes beat {
      0% {
        transform: scale(1) ;
      }
      50% {
        transform: scale(0.6);
      }
    }
    ion-refresher {
      z-index: 100;
      // background: var(--ion-color-dark-contrast);
    }
    .dateModal{
      --background: transparent;
        --box-shadow:none;
        backdrop-filter: blur(5px);
  //       --width: 100%;
  // --height: 100%;
  app-date-picker{
    // padding: 10px;
  background: #0000007a;
  }
    }
@media only screen and (min-width: 700px) {
.dateModal{
  // --background: transparent;
  //   --box-shadow:none;
    --width: 100%;
--height: 100%;
app-date-picker{
padding: 10px;
// background: #0000007a;
}
}
}